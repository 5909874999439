<template>
<div id="qqwallet">
  <b-field class="markdown-body">
    <help-qqwallet />
  </b-field>
  <b-message v-if="editType==='approving' && batchMessage" type="is-success" has-icon>
    {{batchMessage}}
  </b-message>
  <b-table :data="records"
    paginated backend-pagination
    detailed detail-key="id"
    :is-row-checkable="(row) => row.status === 0"
    :per-page="perPage"
    :total="total"
    :current-page="currentPage"
    @page-change="onPageChange">
    <b-table-column #default="props" field="id" label="ID" width="40" numeric>
      {{ props.row.id }}
    </b-table-column>
    <b-table-column #default="props" field="status" label="提现" width="80">
      <b-tag :type="statusColor(props.row.status)"> {{ statusText(props.row.status) }}</b-tag>
    </b-table-column>
    <b-table-column #default="props" field="sagiuid" label="sagiuid" width="120">
      {{ props.row.sagiuid }}
    </b-table-column>
    <b-table-column #default="props" field="money" label="金额(元)" width="120">
      {{ props.row.money }}
    </b-table-column>
    <b-table-column #default="props" field="ipnetwork" label="IP地址" width="80">
      {{ props.row.ipnetwork }}
    </b-table-column>
    <b-table-column #default="props" field="tradeid" label="订单号">
      {{ props.row.tradeid }}
    </b-table-column>
    <b-table-column #default="props" field="transfer_time" label="提现到账时间">
      {{ formatDateTime(Number.parseInt(props.row.transfer_time)*1000) }}
    </b-table-column>
    <b-table-column #default="props" field="note" label="原因">
      {{ props.row.note }}
    </b-table-column>
    <template #detail="{row}">
      <p><strong>提现发起时间：</strong>{{ formatDateTime(Number.parseInt(row.pay_time)*1000) }}</p>
      <p><strong>订单创建时间：</strong>{{ formatDateTime(row.createtime) }}</p>
      <p><strong>原始订单号：</strong>{{ row.transaction_id }}</p>
    </template>
    <template #bottom-left>
      <b>总计：</b>{{ total }}
    </template>
  </b-table>
</div>
</template>

<script>
import cache from '@/core/cache'
import config from '@/core/config'

import HelpQqwallet from '@/help/qqwallet.md'

export default {
  components: { HelpQqwallet },
  mounted () {
    this.getRecords()
  },
  computed: {
    regional () {
      return cache.getR(true)
    }
  },
  data () {
    return {
      editType: 'record',
      open: false,
      perPage: config.PER_PAGE,
      currentPage: 1,
      total: 0,
      tabIndex: 0,
      records: [
      ],
      note: null,
      checkedRows: [],
      ids: [],
      approve: true,
      batchMessage: null,
      user: cache.getUser()
    }
  },
  methods: {
    statusColor (status) {
      if (status === 0) {
        return 'is-info'
      } else if (status === 1) {
        return 'is-success'
      }
      return 'is-danger'
    },
    statusText (status) {
      if (status === 0) {
        return '审核中'
      } else if (status === 1) {
        return '成功'
      }
      return '失败'
    },
    onPageChange (page) {
      this.currentPage = page
      this.getRecords(page)
    },
    onGotoApprove () {
      for (const row of this.checkedRows) {
        this.ids.push(row.id)
      }
      this.editType = 'approve'
    },
    async getRecords (page) {
      page = page || this.currentPage || 1
      try {
        const data = await this.mjp.get2({
          url: '/qqwallet/withdraw-record/',
          query: { page: page, per_page: this.perPage },
          mjpType: 'mp'
        })
        this.records = data.withdraws
        this.currentPage = data.page
        this.perPage = data.per_page
        this.total = data.total
        this.open = data.open
      } catch (error) {
        console.error(error)
      }
    },
    async batchApprove (id) {
      if (id === undefined) {
        id = this.ids.pop()
      }
      if (id === undefined) {
        this.batchMessage = null
        this.hub.alert('操作全部完成！', 5)
        this.editType = 'record'
        return
      }
      const action = this.approve ? '通过' : '拒绝'
      this.batchMessage = `正在${action}提现申请 ${id}...`
      if (this.note) {
        this.batchMessage += `拒绝原因： ${this.note}`
      }
      const data = { approve: this.approve, id, adminuid: this.user.uid, note: this.note }
      try {
        await this.mjp.post2({
          url: '/hongbao/withdraw-approve/',
          data,
          query: { r: this.regional },
          mjpType: 'mp'
        })
        this.batchApprove()
      } catch (error) {
        console.error(error)
        this.editType = 'record'
      }
    },
    async onApproveClick () {
      this.editType = 'approving'
    }
  },
  watch: {
    editType (to, from) {
      if (to === 'approve') {
        this.tabIndex = 1
      } else if (to === 'approving') {
        this.tabIndex = 0
        this.batchApprove()
      } else if (to === 'record') {
        this.tabIndex = 0
        this.note = null
        this.batchMessage = null
        this.checkedRows = []
        this.ids = []
        this.getRecords()
      }
    }
  }
}
</script>

<style>
.detail-buttons {
  padding-top: 10px;
}
</style>
